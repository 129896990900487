import React from "react";

import PageWrapper from "../components/PageWrapper";

const Legal = () => {
  return (
    <>
      <PageWrapper>
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Aviso legal</h2>
                  <p className="font-size-7 mb-8">
                    INFORMACIÓN LEGAL. USO Y NAVEGACIÓN DEL SITIO
                  </p>
                  <p className="font-size-5 mb-0">Versión: 3.32</p>
                  <p className="font-size-5 mb-0">
                    Fecha de publicación: 26 de abril de 2023
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <div>
                      <h5 className="font-size-9 mb-7">
                        1. Contenido y conformidad
                      </h5>
                      <p className="font-size-7 heading-default-color">
                        La presente Información Legal incluye las condiciones de
                        acceso, uso, navegación y condiciones legales aplicables
                        al sitio web https://timeview.io (en adelante, el “
                        <strong>Sitio Web</strong>”), habilitado principalmente
                        para conocer los servicios ofrecidos por la empresa
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (“DESIC”).
                      </p>
                      <p className="font-size-7 heading-default-color">
                        “<strong>Usuario”</strong> es la persona que accede,
                        utiliza o navega a través del Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        La información legal completa del Sitio Web (en
                        adelante, la “
                        <strong>Información Legal Completa</strong>”) está
                        conformada con carácter conjunto, constituyendo una
                        unidad, por el texto contenido en el presente documento
                        (que incluye las Condiciones de acceso, uso y
                        navegación), así como por la Política de Privacidad,
                        documentos a los que puede acceder directamente mediante
                        enlace en la parte inferior del Sitio Web (en adelante,
                        todos los documentos mencionados conjuntamente
                        denominados como la “
                        <strong>Información Legal Completa</strong>”). En
                        consecuencia, la interpretación y consideración de la
                        Información Legal del Sitio Web ha de realizarse de
                        forma conjunta por todos los mencionados documentos.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El acceso, uso y navegación del Sitio Web por el
                        Usuario, implica su consentimiento expreso y su plena
                        conformidad con el contenido íntegro de la denominada “
                        <strong>Información Legal Completa</strong>” (documento
                        de Información Legal que incluye Condiciones de acceso,
                        uso y navegación, documento de Política de Privacidad),
                        por lo que si no está de acuerdo con alguno de los
                        términos dispuestos en estos documentos, le rogamos no
                        nos facilite datos de carácter personal ni utilice el
                        Sitio Web ni ninguno de los servicios puestos a su
                        disposición en el mismo.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario puede imprimir y almacenar digitalmente todos
                        los documentos mencionados, encontrándose a su
                        disposición permanente mediante enlace de acceso directo
                        disponible en la parte inferior del Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Se recomienda al Usuario la lectura íntegra de la
                        Información Legal Completa (Información Legal, Política
                        de Privacidad), de forma regular, incluso cada vez que
                        acceda al Sitio Web y, en cualquier caso, cada vez que
                        decida facilitarnos datos de carácter personal a través
                        del Sitio Web, pues su contenido puede estar sujeto a
                        modificaciones. Cualquier modificación será debidamente
                        publicada y estará siempre accesible en el Sitio Web,
                        haciendo referencia a su versión y fecha.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-9 mb-7">
                        2. Identificación del titular del dominio y prestador de
                        servicios y medios de contacto.
                      </h5>
                      <p className="font-size-7 heading-default-color mb-9">
                        En cumplimiento de lo dispuesto en la Ley 34/2002, de 11
                        de julio, de Servicios de la Sociedad de la Información
                        y Comercio Electrónico (LSSICE), se informa que el
                        titular del dominio https://timeview.io (el Sitio Web) y
                        la empresa que presta sus servicios y ofrece sus
                        productos a través del mismo, así como el responsable
                        del tratamiento de los datos es:
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <strong>
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC)
                        </strong>
                        , C.I.F. nº B-35492784, domicilio social y postal C/
                        Camelia, 15-17 C.P. 35010 Las Palmas de Gran Canaria
                        (Gran Canaria-España). Datos registrales: inscrita en el
                        Registro Mercantil de Las Palmas, al Tomo 1.357 General
                        de Sociedades, Folio 40, Hoja GC-18.504, inscripción 1ª.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Para establecer contacto directo</u> con DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC):
                        <br />
                        - Dirección postal/domicilio: C/ Camelia, 15-17 C.P.
                        35010 Las Palmas de Gran Canaria (Gran Canaria-España).
                        <br />- Teléfono: (+34) 928.37.40.26 / 928.37.40.28.
                        <br />- Correo electrónico:{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:desic@desic-sl.com"
                        >
                          desic@desic-sl.com
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>
                          Para ejercicio de derechos en protección de datos de
                          carácter personal
                        </u>
                        : <br />- Dirección postal/domicilio: C/ Camelia, 15-17
                        C.P. 35010 Las Palmas de Gran Canaria (Gran
                        Canaria-España). <br />- Teléfono: (+34) 928.37.40.26 /
                        928.37.40.28. <br />- Correo electrónico:
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:rgpd@desic-sl.com"
                        >
                          rgpd@desic-sl.com
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>
                          Para establecer contacto con el Delegado de Protección
                          de Datos
                        </u>
                        : <br />- Correo electrónico:{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:dpd@desic-sl.com"
                        >
                          dpd@desic-sl.com
                        </a>
                        .
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-9 mb-7">
                        3. Condiciones de acceso, uso y navegación del sitio web
                      </h5>
                      <p className="font-size-7 heading-default-color">
                        El presente apartado regula las Condiciones de Acceso,
                        Uso y Navegación del Sitio Web, incluyendo todos los
                        contenidos, productos y servicios puestos a disposición
                        del Usuario.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.1. Acceso, uso y navegación
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        El acceso, uso y navegación por los contenidos
                        publicados en el Sitio Web tiene carácter gratuito,
                        salvo el coste de la conexión a través de la red de
                        telecomunicaciones suministrada por el proveedor de
                        acceso contratado por el Usuario.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El acceso, uso y navegación por los contenidos
                        publicados en el Sitio Web es libre y está permitido a
                        cualquier usuario de Internet. No obstante, para
                        facilitar datos de carácter personal por el Usuario será
                        necesario que el Usuario disponga de la capacidad legal
                        necesaria para facilitar por sí mismo sus datos de
                        carácter personal o en su caso, tenga el consentimiento
                        de sus representantes legales, según se establezca en la
                        Política de Privacidad de este Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Los contenidos del Sitio Web se facilitan para su
                        navegación en español e inglés, siendo el español el
                        idioma en el que ha sido redactado el contenido de la
                        Información Legal Completa (conforme a lo dispuesto en
                        el apartado 3.7).
                      </p>
                      <p className="font-size-7 heading-default-color">
                        En cuanto a las recomendaciones de visualización del
                        Sitio Web, el mismo funciona de manera óptima con los
                        siguientes navegadores: Firefox 15 o superior, Google
                        Chrome 25 o superior, Internet Explorer 9 o superior,
                        Opera 12 o superior, Safari 6 o superior.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.2. Registro de usuarios
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        El acceso, uso y navegación a través del Sitio Web no
                        requiere registro por parte del Usuario. Para la
                        prestación de determinados servicios sí será preciso que
                        el Usuario facilite sus datos de carácter personal para
                        cada uno de los servicios que voluntariamente decida
                        utilizar, los cuales serán tratados conforme a la
                        Política de Privacidad del presente Sitio Web.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.3. Obligaciones del usuario
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        El usuario se compromete a utilizar el Sitio Web
                        conforme a la ley, la moral, las buenas costumbres, el
                        orden público y la Información Legal Completa,
                        obligándose a abstenerse de utilizar el Sitio Web o sus
                        contenidos con fines o efectos ilícitos, prohibidos,
                        contrarios a la buena fe y al orden público o contrarios
                        a lo dispuesto en la Información Legal Completa.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Queda expresamente prohibido el uso del Sitio Web con
                        fines lesivos de bienes o intereses de DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC) o de
                        terceros, o mediante actuaciones que pudieran provocar
                        daños o sobrecarga de sistemas físicos y lógicos (redes,
                        servidores, equipos y aplicaciones informáticas) de
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (DESIC), de sus proveedores o de terceras personas. En
                        caso de incumplimiento, el Usuario responderá de los
                        daños y perjuicios que pudieran producirse.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario no podrá incluir en el Sitio Web o difundir a
                        través del mismo: a) Información que se considere
                        discriminatoria, injuriosa, calumniosa, amenazadora,
                        xenófoba o incitadora de la violencia; b) Información
                        sobre la que no disponga de los derechos legales
                        oportunos, incluidos los derivados de la propiedad
                        intelectual e industrial; c) Información publicitaria o
                        de promoción comercial propia o de terceros.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Queda expresamente prohibida la suplantación de
                        identidad de cualquier forma, la adopción de identidades
                        falsas, como la aportación de cualesquiera datos de
                        carácter personal o información de terceros como propios
                        del Usuario, cuentas de correo electrónico de terceros
                        como propias para establecer comunicación a través del
                        Sitio Web y/o a través de los correos electrónicos
                        habilitados en el mismo.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario se obliga a proporcionar a través de los
                        medios dispuestos en el Sitio Web únicamente información
                        veraz, siendo directamente responsable de cualquier daño
                        derivado del incumplimiento de esta obligación ante
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (DESIC) y/o ante terceros.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Queda expresamente prohibida la manipulación de los
                        mensajes enviados a través de correo electrónico, así
                        como la alteración de la información o documentos
                        electrónicos de DESARROLLO Y SISTEMAS INFORMÁTICOS
                        CANARIAS, S.L. (DESIC) o de terceros facilitados a
                        través del Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario se obliga al cumplimiento de todos los
                        extremos dispuestos en la Información Legal Completa y
                        en cualquiera de los documentos que conforman la misma,
                        pudiendo ser excluido del acceso al Sitio Web en caso de
                        incumplimiento. DESARROLLO Y SISTEMAS INFORMÁTICOS
                        CANARIAS, S.L. (DESIC) podrá suspender en cualquier
                        momento y sin previo aviso la prestación del servicio y,
                        en su caso, retirar los contenidos que estime
                        incorrectos o ilegales o que incumplan la Información
                        Legal Completa, bien a criterio propio o a instancia de
                        tercero afectado o de autoridad competente, sin
                        perjuicio del ejercicio de las actuaciones oportunas.
                        Dicha suspensión no podrá dar lugar a indemnización
                        alguna a favor del Usuario.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.4. Responsabilidades y garantías. Exención y
                        limitación de responsabilidad
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        El Usuario es responsable directo del uso que realice
                        del y en el Sitio Web y del incumplimiento de lo
                        dispuesto en la Información Legal Completa.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (DESIC) no será responsable, en ningún caso, de los
                        posibles errores, faltas de actualización u omisiones en
                        los contenidos del Sitio Web, así como de la efectividad
                        o exactitud de los mismos, siendo meramente
                        orientativos, de la falta de disponibilidad,
                        continuidad, fallos de acceso, virus informáticos,
                        averías telefónicas, desconexiones, retrasos, bloqueos,
                        sobrecargas en Internet, ataques informáticos,
                        intromisiones de terceros, interferencias,
                        interrupciones, realizando esfuerzos y adoptando medidas
                        para evitar estas circunstancias.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (DESIC) no será responsable de las opiniones vertidas
                        por el Usuario o por terceras personas a través de foros
                        u otras herramientas de participación que pudieran
                        habilitarse en el Sitio Web, o que se difundan o
                        tramiten a través del Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (DESIC) se reserva el derecho a modificar, total o
                        parcialmente o eliminar en cualquier momento y sin
                        previo aviso, los contenidos y servicios ofrecidos a
                        través del Sitio Web, así como a suspender temporalmente
                        los contenidos o servicios o el propio Sitio Web, por
                        mantenimiento, actualización o mejora.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.5. Política de enlaces (links)
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        El Sitio Web puede contener enlaces a sitios web de
                        terceros ajenos a DESARROLLO Y SISTEMAS INFORMÁTICOS
                        CANARIAS, S.L. (DESIC). Por tanto, DESARROLLO Y SISTEMAS
                        INFORMÁTICOS CANARIAS, S.L. (DESIC) no se hace
                        responsable del contenido, informaciones o servicios
                        ofrecidos por terceros a través de los enlaces, del
                        funcionamiento del sitio web enlazado ni de los posibles
                        daños que pudiesen derivarse de su acceso, uso y
                        navegación por el Usuario. El establecimiento de dichos
                        enlaces no implica relación alguna entre DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC) y los
                        titulares de los contenidos o del sitio web donde se
                        encuentren, salvo la existencia de acuerdos con algunos
                        de sus titulares. No obstante, en ningún caso DESARROLLO
                        Y SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC) controla
                        ni ejerce ningún tipo de dirección o autoridad en sitios
                        web de terceros a los que se pudiera incluir enlace en
                        el Sitio Web, ni da su aceptación o conformidad a sus
                        contenidos, por lo que aconsejamos al Usuario, en caso
                        de acceso y uso de los mismos por su cuenta y riesgo, a
                        consultar las condiciones legales, la política de
                        privacidad/protección de datos de carácter personal y la
                        política de cookies que se contenga en cada uno de
                        ellos. Los enlaces (links) se facilitan únicamente a
                        efectos informativos. DESARROLLO Y SISTEMAS INFORMÁTICOS
                        CANARIAS, S.L. (DESIC) podrá retirar en cualquier
                        momento y sin previo aviso enlaces de terceros que
                        aparecen en el Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Si el Usuario tuviera conocimiento efectivo de que los
                        enlaces remiten a actividad o información ilícita o
                        lesiva de bienes o derechos de un tercero, le rogamos
                        nos lo informe a través de correo electrónico utilizando
                        las vías de contacto dispuestas en el apartado 2 de este
                        documento para llevar a cabo las acciones oportunas, en
                        su caso.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Sólo se podrán añadir enlaces en otros sitios web al
                        presente Sitio Web contando con la previa autorización
                        por escrito de DESARROLLO Y SISTEMAS INFORMÁTICOS
                        CANARIAS, S.L. (DESIC).
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.6. Propiedad intelectual e industrial
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (DESIC), o los terceros titulares de los elementos
                        sujetos a propiedad intelectual e industrial contenidos
                        en el Sitio Web, son titulares de todos los derechos
                        derivados de propiedad intelectual e industrial de este
                        Sitio Web y de los elementos contenidos en el mismo, a
                        título enunciativo, elementos como gráficos, logos,
                        marcas, nombres comerciales, signos distintivos,
                        estructura web, diseño, botones, iconos, código fuente,
                        software, textos, fotografías, archivos de audio, vídeos
                        y demás elementos sujetos a propiedad intelectual y/o
                        industrial incluidos en el Sitio Web. Quedan reservados
                        todos los derechos de propiedad intelectual e industrial
                        respecto a los mismos. El acceso, uso y navegación por
                        el Sitio Web y la adquisición de productos y/o servicios
                        ofrecidos a través del Sitio Web, en su caso, no
                        implican renuncia, transmisión, licencia o cesión total
                        ni parcial de los mencionados derechos de propiedad
                        intelectual e industrial por parte del titular de los
                        mismos, ni siquiera expectativa de derecho alguna.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Queda expresamente prohibida, sin la previa autorización
                        por escrito de los titulares de los derechos de
                        propiedad intelectual o industrial (DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC) o terceros
                        correspondientes), la utilización, copia, reproducción,
                        distribución, comercialización, transformación y
                        comunicación pública, incluida la puesta a disposición,
                        de la totalidad o parte de los contenidos del Sitio Web,
                        con fines públicos o comerciales, en cualquier soporte y
                        por cualquier medio técnico. El Usuario dispone de un
                        derecho de uso estrictamente personal y privado del
                        contenido del Sitio Web, pudiendo proceder a imprimir,
                        copiar o almacenar el mismo exclusivamente en el ámbito
                        personal y privado y únicamente para su uso en ese
                        ámbito.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        La utilización no autorizada del contenido del Sitio
                        Web, así como los perjuicios que la misma pudiera
                        ocasionar a DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS,
                        S.L. (DESIC) o a terceros, podrán dar lugar al ejercicio
                        de las acciones legales oportunas y a las
                        responsabilidades e indemnizaciones correspondientes,
                        las cuales serán de cuenta del Usuario. El Usuario
                        deberá valorar, además, que en algunas de las
                        fotografías y vídeos contenidos en el presente Sitio Web
                        aparecen personas físicas, considerándose la imagen un
                        dato de carácter personal y por lo tanto sometido al
                        amparo de lo dispuesto en la normativa vigente en
                        protección de datos de carácter personal (Reglamento
                        (UE) 2016/679 del Parlamento Europeo y del Consejo, de
                        27 de abril de 2016, relativo a la protección de las
                        personas físicas en lo que respecta al tratamiento de
                        datos personales y a la libre circulación de estos datos
                        (“Reglamento Europeo de Protección de Datos” o “RGPD”) y
                        la normativa nacional vigente en protección de datos),
                        de manera que el Usuario no podrá utilizar las
                        mencionadas imágenes fuera del ámbito estrictamente
                        personal y privado y limitado exclusivamente a su
                        experiencia de navegación a través del Sitio Web.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">3.7. Idioma</h6>
                      <p className="font-size-7 heading-default-color">
                        El idioma de navegación disponible es el español. El
                        contenido de todos los documentos que conforman la
                        Información Legal Completa ha sido redactado en español.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.8. Duración y modificación de la Información Legal
                        Completa
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        La Información Legal Completa aplicable será la
                        publicada en el Sitio Web en cada momento. DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC), se reserva
                        el derecho a modificar, total o parcialmente, en
                        cualquier momento y sin previo aviso, el contenido de la
                        Información Legal Completa, incluyendo cualquiera de los
                        documentos que la conforman, es decir, el texto
                        contenido en el presente documento de Información Legal,
                        en la Política de Privacidad, por ampliación, mejora,
                        concreción o actualización de su contenido, tanto por
                        cambios legislativos o jurisprudenciales o de estrategia
                        empresarial, sin que esta modificación tenga carácter
                        retroactivo. En caso de modificación, dejará de ser
                        aplicable la versión anterior, siendo de aplicación el
                        documento modificado a partir de la fecha de su
                        publicación.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Todos los documentos mencionados estarán siempre
                        accesibles en el Sitio Web mediante enlace en la parte
                        inferior del mismo, con información sobre el número de
                        la versión de que se trata y fecha de publicación, a
                        partir de la cual entra en vigor.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.9. Nulidad y eficacia
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        Si alguna de las cláusulas o textos incluidos en este
                        documento fuera declarado nulo, el propio documento
                        seguirá siendo plenamente eficaz y obligatorio para las
                        partes en los mismos términos, siempre que pueda
                        subsistir sin las cláusulas o textos declarados nulos.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13 pl-8">
                      <h6 className="font-size-8 mb-7">
                        3.10. Legislación y jurisdicción aplicable
                      </h6>
                      <p className="font-size-7 heading-default-color">
                        La legislación aplicable será la española. Salvo que
                        resulte de aplicación normativa específica que
                        establezca otra jurisdicción, el Usuario y DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC), se someten
                        expresamente a los Juzgados y Tribunales de Las Palmas
                        de Gran Canaria para cualquier controversia. Todo ello
                        sin perjuicio de nuestra disposición a la resolución de
                        toda controversia previamente de forma amistosa.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-9 mb-7">
                        4. Política de privacidad y cookies
                      </h5>
                      <p className="font-size-7 heading-default-color">
                        A través del Sitio Web pueden recogerse datos de
                        carácter personal para su tratamiento tanto a través de
                        los formularios habilitados al efecto, como mediante el
                        envío de correos electrónicos a las direcciones de
                        contacto. Para más información sobre el tratamiento de
                        datos de carácter personal por DESARROLLO Y SISTEMAS
                        INFORMÁTICOS CANARIAS, S.L. (DESIC) y las vías de
                        ejercicio de sus derechos en materia de protección de
                        datos de carácter personal, consulte nuestra{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline"
                          href="/privacy"
                        >
                          Política de Privacidad
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Sitio Web utiliza cookies de terceros de Google para
                        protegerse de spam y fraude con el reCAPTCHA. Se aplica
                        la{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline"
                          href="https://policies.google.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Política de Privacidad
                        </a>{" "}
                        de Google y los{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline"
                          href="https://policies.google.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Términos del Servicio
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        La Política de Privacidad se encuentra a su continúa
                        disposición y acceso mediante enlace directo en la parte
                        inferior del Sitio Web con la mencionada denominación.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Legal;
